import type { NextPage } from 'next';
import Link from 'next/link';
import Heading from '../src/components/design-system/heading';
import Section from '../src/components/design-system/section';
import Text from '../src/components/design-system/text';
import Tag from '../src/components/design-system/tag';
import Grid from '../src/components/design-system/grid';
import Card from '../src/components/design-system/card';
import Image from 'next/image';
import Stack from '../src/components/design-system/stack';
import { breakpointSizes } from '../src/styles/breakpoints';

const Home: NextPage = () => {
  return (
    <>
      {/* No <Head> is used here. Home page uses _app.tsx site-wide defaults. */}
      <Section>
        <Stack direction="vertical" size="lg">
          <Grid>
            <Grid.Item span={{ xxs: 12, md: 9, lg: 7 }}>
              <Heading level={1} noMarginTop>
                Online financial calculators
              </Heading>
              <Text>
                Figura aims to provide a suite of powerful tools for Australians
                to achieve a more transparent and accurate view of their
                finances.
              </Text>
            </Grid.Item>
          </Grid>
          <Grid>
            <Grid.Item span={{ xxs: 12, md: 6, lg: 6 }}>
              <Card
                title="Home loan repayment calculator"
                image={
                  <Image
                    src="/images/home-page-cards/home-loan-repayment-calculator.png"
                    alt="The Figura home loan calculator"
                    width="1400"
                    height="933"
                    layout="responsive"
                    sizes={`(min-width: ${breakpointSizes.md}px) 50vw,
                    100vw`}
                    // Needs higher quality because it isn't optimising PNG very well
                    quality={85}
                  />
                }
                renderLink={({ link: LinkComponent, children }) => (
                  <Link passHref href="/calculators/repayments">
                    <LinkComponent>{children}</LinkComponent>
                  </Link>
                )}
              >
                <Tag appearance="information">Beta</Tag>
                <Text>
                  {' '}
                  This provides the most accurate home loan repayment
                  calculations available in Australia. It&apos;s the first and
                  only calculator that runs a{' '}
                  <strong>day-by-day simulation</strong> of the entire term, to
                  replicate a real loan.
                </Text>
              </Card>
            </Grid.Item>
          </Grid>
        </Stack>
      </Section>
    </>
  );
};

export default Home;
